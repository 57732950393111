<template>
  <div>
    <b-overlay :show="show" rounded="sm">
    <b-card>
      <b-form class="mt-2" ref="formHTML">
        <input type="hidden" name="user_id" :value="user.id">
        <!-- media -->
        <b-row>
          <b-col sm="6">
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img v-if="optionsLocal.avatar != null" ref="previewEl" rounded
                    :src="(optionsLocal.avatar.startsWith('data') ? '' : $http.defaults.baseURL + 'storage/app/private/') + optionsLocal.avatar"
                    height="80" />
                </b-link>
                <!--/ avatar -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <!-- upload button -->
                <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
                  Subir
                </b-button>
                <b-form-file ref="refInputEl" v-model="profileFile" name="avatar" accept=".jpg, .png, .gif"
                  :hidden="true" plain @input="inputImageRenderer" />
                <!--/ upload button -->

                <!-- reset -->
                <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75">
                  Borrar
                </b-button>
                <!--/ reset -->
                <b-card-text>Permitidos ficheros JPG, GIF or PNG. Máximo tamaño 800kB</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!--/ media -->
        </b-row>
        <hr>
        <!-- form -->
        <b-row>
          <b-col sm="6">
            <b-form-group label="Nombre" label-for="account-name">
              <b-form-input v-model="optionsLocal.name" placeholder="nombre" name="name" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Apellidos" label-for="account-surname">
              <b-form-input v-model="optionsLocal.surname" name="surname" placeholder="Apellidos" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="NIF" label-for="account-nif">
              <b-form-input v-model="optionsLocal.nif" name="nif" placeholder="NIF" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Empresa" label-for="account-business_name">
              <b-form-input v-model="optionsLocal.business_name" name="business_name" placeholder="Empresa" />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Direccción" label-for="account-address">
              <b-form-input v-model="optionsLocal.address" name="address" placeholder="Dirección" />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Teléfono 1" label-for="account-phone">
              <b-form-input v-model="optionsLocal.number" name="phone" />
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Teléfono 2" label-for="account-phone">
              <b-form-input v-model="optionsLocal.number" name="phone2" />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="C.P." label-for="account-cp">
              <b-form-input v-model="optionsLocal.cp" name="cp" />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="Localidad" label-for="account-locality">
              <b-form-input v-model="optionsLocal.locality" name="locality" placeholder="Localidad" />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />
            </b-form-group>
          </b-col>
          <b-col sm="12" class="mt-2 mb-2">
            <h4>Subir documentos</h4>             
          </b-col>
          <b-col sm="3">
            <b-form-group label="DNI" label-for="account-dni">
              <b-form-file name="dni" placeholder="Elegir archivo"></b-form-file>                                 
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Fecha caducidad DNI" label-for="account-caducidad">
              <b-form-datepicker id="datepicker-dateformat1" label-no-date-selected="fecha no seleccionada" name="dni_expiration_date"  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
            </b-form-group>
          </b-col>
          
          <b-col sm="3">
            <b-form-group label="Recibo autónomo" label-for="account-autnomo">
              <b-form-file name="receipt_file" placeholder="Elegir archivo"></b-form-file>      
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Fecha caducidad recibo" label-for="account-caducidad">
              <b-form-datepicker id="datepicker-dateformat2" label-no-date-selected="fecha no seleccionada" name="receipt_expiration_date" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group label="Documento hacienda" label-for="account-hacienda">
              <b-form-file name="hacienda_file" placeholder="Elegir archivo"></b-form-file>      
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Fecha cad. doc. hacienda" label-for="account-caducidad">
              <b-form-datepicker id="datepicker-dateformat3" label-no-date-selected="fecha no seleccionada"  name="hacienda_expiration_date" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
            </b-form-group>
          </b-col>
          
          <b-col sm="3">
            <b-form-group label="Pagos Seguridad social" label-for="account-sspagos">
              <b-form-file name="sscharges_file" placeholder="Elegir archivo"></b-form-file>      
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Fecha caducidad Seg. social" label-for="account-caducidad">
              <b-form-datepicker id="datepicker-dateformat4" label-no-date-selected="fecha no seleccionada" name="charges_expiration_date" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
            </b-form-group>
          </b-col>


          <b-col sm="3">
            <b-form-group label="Seguro respons. civil" label-for="account-seguro">
              <b-form-file name="respons_civil_file" placeholder="Elegir archivo"></b-form-file>      
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group label="Fecha caducidad seguro" label-for="account-caducidadseguro">
              <b-form-datepicker id="datepicker-dateformat5" label-no-date-selected="fecha no seleccionada" name="seguro_expiration_date" :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }" locale="es"></b-form-datepicker>
            </b-form-group>
          </b-col>         

        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" class="mt-2 mr-1" type="button" @click="updateForm">
              Guardar cambios
            </b-button>
            <b-button variant="warning" class="mt-2 mr-1" type="button" link to="/">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormRadio, BOverlay,BCardHeader, BCardBody, BBadge, BFormGroup, BFormInput, BFormDatepicker, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapState } from 'vuex';

export default {
  components: { BButton, BForm, BCardHeader, BCardBody, BBadge, BImg, BFormFile, BFormGroup, BFormInput, BFormRadio, BFormDatepicker, BRow, BOverlay, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, },
  data() {
    return {
      show : false,
      profileFile: null,
    }
  },
  mounted() {
    this.getUser();  
  },
  methods: {
    logout: function () {
      this.$store.dispatch("auth/logout")
      this.$router.push('/login');
    },
   getUser: function () {
      this.show = true;
        this.$http.get("/api/getUser?id=" + this.user.id).then((response) => {
          this.optionsLocal = {
            avatar: response.data['avatar'],
            name: response.data['name'],
            surname: response.data['surname'],
            business_name: response.data['business_name'],
            address: response.data['address'],
            number: response.data['number'],
            cp: response.data['cp'],
            locality: response.data['locality'],
            nif: response.data['nif'],
            email: response.data['email']
          }
          this.subscription = response.data['subscription']
          this.show = false;
        });      
    },
    updateForm: async function (e) {
      /* formData */
      var formData = new FormData(this.$refs.formHTML);
      
      /* AJAX request */
      await this.$http({
        method: "post",
        url: "/api/updateProfile",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { window.console.log(response.data); })
        .catch(response => { window.console.log(response) });
      this.$router.push('/');
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const optionsLocal = ref(
      JSON.parse(
        JSON.stringify({
          avatar: null,
          name: '',
          surname: '',
          business_name: '',
          address: '',
          number: '',
          cp: '',
          locality: '',
          nif: '',
          email: '',
        })
      )
    );

    const { inputImageRenderer } = useInputImageRenderer(refInputEl,

      (base64) => {
        // eslint-disable-next-line no-param-reassign
        optionsLocal.value.avatar = base64;
      }
    )

    return {
      refInputEl,
      previewEl,
      optionsLocal,
      inputImageRenderer,
    }
  },
}
</script>
